import { Router, RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import Index from "@/views/Index.vue";
import Sitemap from "@/views/sitemap/Sitemap.vue";
import PageNotFound from "@/views/errors/PageNotFound.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "ConvertFormats",
        component: Index,
        meta: {
            title: "ConvertFormats",
        },
    },
    {
        path: "/sitemap",
        name: "Sitemap",
        component: Sitemap,
        meta: {
            title: "Sitemap",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
    {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound,
        meta: {
            title: "Pagina niet gevonden",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
];

const router: Router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach((to) => {
    if (to.meta.title == null) {
        document.title = "ConvertFormats";
    } else {
        let appendix: string;

        if (to.fullPath == "/") {
            appendix = "";
        } else {
            appendix = " | ConvertFormats";
        }

        document.title = to.meta.title as string + appendix;
    }
});

export default router;
